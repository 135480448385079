/**
 * 将url中的query参数解析成对象
 * 
 * @param {*} url  待解析的完整url
 */
export const getUrlParams = (url) => {
	if (url.includes('?')) {
		return JSON.parse(`{"${decodeURI(url.split("?")[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
	}
	return {}
}

/**
 * 将对象转为解析为 url query 参数字符串
 * 
 * @param {*} obj 待转化的对象
 */
export const objectToUrlParams = (obj) => {
  const queryParams = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return queryParams.length ? `?${queryParams.join('&')}` : ''
}

/**
 * 兼容小程序 webview 传参的 '&query=' 参数处理
 * 
 * @param {*} url 可能含有 '&query=' 字符串的 url
 * @returns 
 */
export const webviewUrlHandler = (url) => {
  const flag = '&query='
  if (url.includes(flag)) {
    const [baseUrl, queryJsonStr] = url.split(flag)
    const queryStr = objectToUrlParams(JSON.parse(queryJsonStr))
    return `${baseUrl}${queryStr}`
  }
  return url
}