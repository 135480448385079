/**
 * 统一管理项目中用到的常量
 */

// 图片路径的base url
export const IMG_DOMAIN = 'http://img.miaocang.cc/'

// 苗仓H5项目的base url
export const MC_H5_BASE_URL = process.env.NODE_ENV === 'production' ? `https://mobi.miaocang.cc` : `http://tmobi.miaocang.cc:85`

// 后台配置的url中, 可能存在的小程序页面路径列表
export const MINI_PROGRAM_PAGE_LIST = [
  // 探花姐姐
  {
    name: 'CloudFindFlowerSisterList',
    url: '/pages/findFlowerSister/findFlowerSister'
  },
  // 展商介绍
  {
    name: 'CloudExhibitorList',
    url: '/pages/exhibitor/index/index'
  },
  // 资讯详情
  {
    name: 'CloudNewsDetail',
    url: '/pages/news/detail/detail'
  }
]