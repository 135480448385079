<template>
  <div class="cloud-exhibition-list">
    <AddressPosition />
    <!-- VR展 -->
    <div class="list-container">
      <div class="header" :class="`bg-${year}`">
        <img :src="bigDataIMG.i_vr" alt="" class="icon" style="width: 31px; height: 23px" />
        <span class="text">VR展</span>
      </div>
      <div v-if="loading || list && list.length" class="list">
        <el-row :gutter="52">
          <el-col v-for="item in loading ? 6 : list" :key="loading ? item : item.id" :span="8">
            <el-skeleton :loading="loading" animated style="width: 373px;">
              <ShadowCard
                :cover-src="item.image"
                cover-width="373px"
                cover-height="281px"
                clickable
                @click.native="toUrlPage(item.url)"
              >
                <template #content>
                  <div class="card-title">{{ item.title }}</div>
                </template>
              </ShadowCard>
              <template #template>
                <el-skeleton-item variant="image" style="width: 373px; height: 281px; margin-bottom: 40px;" />
                <div style="padding: 14px;">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div style="display: flex; align-items: center; justify-items: space-between;">
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
      <el-empty v-else-if="!loading" :image-size="200" />
    </div>
    <!-- 图片展 -->
    <div class="list-container">
      <div class="header" :class="`bg-${year}`">
        <img :src="bigDataIMG.i_photo" alt="" class="icon" style="width: 32px; height: 28px" />
        <span class="text">图片展</span>
      </div>
      <div v-if="loading || newsList && newsList.length" class="list">
        <el-row :gutter="52">
          <el-col v-for="item in loading ? 6 : newsList" :key="loading ? item : item.id" :span="8">
            <el-skeleton :loading="loading" animated style="width: 373px;">
              <ShadowCard
                :cover-src="item.newsCover"
                cover-width="373px"
                cover-height="281px"
                clickable
                @click.native="toNewsDetailPage(item.id)"
              >
                <template #content>
                  <div class="card-title">{{ item.newsTitle }}</div>
                </template>
              </ShadowCard>
              <template #template>
                <el-skeleton-item variant="image" style="width: 373px; height: 281px; margin-bottom: 40px;" />
                <div style="padding: 14px;">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div style="display: flex; align-items: center; justify-items: space-between;">
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
      <el-empty v-else-if="!loading" :image-size="200" />
      <!-- 分页 -->
      <pagination
        v-show="newsTotal > 0"
        :total="newsTotal"
        :page.sync="newsListQuery.pageNum"
        :limit.sync="newsListQuery.pageSize"
        :auto-scroll="false"
        @pagination="getNewsListData"
      />
    </div>
  </div>
</template>

<script>
import { getExhibitionBannerList, NewsList } from '@/api'
import ShadowCard from '@/components/ShadowCard.vue'
import Pagination from '@/components/Pagination.vue'
import AddressPosition from '@/components/AddressPosition'
import imgSrc from "@/utils/bigDataIMG.js"
import { webviewUrlHandler, getUrlParams } from '@/utils/url'
import { MINI_PROGRAM_PAGE_LIST } from '@/utils/constant'
const bigDataIMG = imgSrc.data()

export default {
  name: 'CloudExhibitionList',

  components: { AddressPosition, ShadowCard, Pagination },

  data() {
    return {
      // 列表
      list: [],
      loading: true,
      // 云观展图片展列表
      newsList: [],
      newsTotal: 0,
      // 云观展图片展列表请求参数
      newsListQuery: {
        // 云观展图片展类型, newsType默认传'4'
        newsType: '4',
        pageNum: 1,
        pageSize: 6
      },
      bigDataIMG,
      // 当前需要请求的年份. 默认值: '2023'
      year: '2023'
    }
  },

  created() {
    this.init()
  },

  methods: {
    /* 页面数据初始化 */
    init() {
      this.loading = true
      const { year = '2023' } = this.$route.query
      this.year = year
      Promise.all([
        this.getListData(),
        this.getNewsListData()
      ]).finally(() => {
        this.loading = false
      })
    },

    /* 获取云观展VR展列表数据 */
    getListData() {
      const { year } = this
      return getExhibitionBannerList({
        // 根据当前需要请求的年份, 构造 `timeBegin` 和 `timeEnd` 入参
        timeBegin: `${year}-01-01 00:00:00`,
        timeEnd: `${year}-12-31 23:59:59`
      }).then(res => {
        const list = res.data
        this.list = list.sort((a, b) => a.seq - b.seq)
      }).catch(error => {
        this.list = []
        console.log('getListData', error)
      }).finally(() => {
        this.loading = false
      })
    },


    /* 获取云观展图片展(云观展图片展)列表数据 */
    getNewsListData(payload = {}) {
      console.log(payload)
      const { year } = this
      const { page: pageNum = 1 } = payload
      // 构造请求参数
      const { newsListQuery } = this
      const query = {
        ...newsListQuery,
        // 根据当前需要请求的年份, 构造 `createTimeBegin` 和 `createTimeEnd` 入参
        createTimeBegin: `${year}-01-01 00:00:00`,
        createTimeEnd: `${year}-12-31 23:59:59`,
        pageNum: pageNum || newsListQuery.pageNum
      }
      NewsList(query).then(res => {
        let { content, total } = res.data
        content.map(item=>{
          if (item.publishedTime)
          item.publishedTime = item.publishedTime.split(' ')[0]
          if (item.newsCover.indexOf('http')==-1) {
            item.newsCover = `http://img.miaocang.cc/${item.newsCover}`
          }
          return item
        })
        this.newsList = content
        this.newsTotal = total
      })
    },

    /* 打开url */
    toUrlPage(url = '') {
      // 先判断url是否为后台配置的小程序页面
      const miniProgramPage = MINI_PROGRAM_PAGE_LIST.find(item => url.includes(item.url))
      if(!miniProgramPage) {
        url && window.open(webviewUrlHandler(url))
      } else {
        this.$router.push({
          name: miniProgramPage.name,
          ...(url.includes('?') && {
            query: getUrlParams(url)
          })
        })
      }
    },

    /* 跳转到云观展图片展详情页 */
    toNewsDetailPage(id) {
      const routeData = this.$router.resolve({
        name: 'CloudExhibitionDetail',
        query: { article: id }
      })
      window.open(routeData.href)
    }
  }
}
</script>

<style lang="less" scoped>
.cloud-exhibition-list {
  width: 1280px;
  margin: 0 auto;
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(332px - 32px * 2);
      height: 70px;
      margin: 70px 0;
      font-size: 28px;
      font-weight: 600;
      color: #fff;
      .icon {
        margin-right: 5px;
        object-fit: cover;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 32px 35px 0;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 32px;
      }
    }
    .bg-2022 {
      background: linear-gradient(90deg, #c9d7ff 0%, #7f95d1 100%);

      &::before {
        border-color: transparent #c9d7ff transparent transparent;
      }

      &::after {
        border-color: transparent transparent transparent #7f95d1;
      }
    }

    .bg-2023 {
      background: linear-gradient(90deg, #9aee96 0%, #349477 100%);

      &::before {
        border-color: transparent #9aee96 transparent transparent;
      }

      &::after {
        border-color: transparent transparent transparent #349477;
      }
    }
    .list {
      .el-row {
        width: 1280px;
      }
      .el-col {
        margin-bottom: 40px;
      }
      .card-title {
        width: 96%;
        height: 97px;
        margin: 0 auto;
        line-height: 97px;
        text-align: center;
        font-size: 18px;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>