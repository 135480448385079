<template>
  <el-card
    :class="{
      'shadow-card': true,
      'clickable': clickable
    }"
    :body-style="{ 
      'padding': 0,
      'height': !hasSlot('content') ? coverHeight : '100%'
    }"
    :style="{ 'width': coverWidth }"
    oncontextmenu="return false;"
  >
    <img
      v-if="coverSrc"
      :src="coverSrc"
      draggable="false"
      class="image"
      :style="{ 'width': coverWidth, 'height': coverHeight }"
    />
    <slot name="content" />
  </el-card>
</template>

<script>
export default {
  name: 'ShadowCard',

  props: {
    coverSrc: {
      type: String,
      default: ''
    },
    coverWidth: {
      type: String,
      default: '384px'
    },
    coverHeight: {
      type: String,
      default: '240px'
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    // 判断是否使用了named slot
    hasSlot(name) {
      return !!this.$slots[name]
    }
  }
}
</script>

<style lang="less" scoped>
.shadow-card {
  user-select: none;
  border-radius: 10px;
  background-color: transparent;
  &:hover {
    box-shadow: 0 0 30px #ccc;
    .tit {
      color: #20a162;
    }
  }
  .image {
    object-fit: cover;
  }
}
.clickable {
  &:hover {
    cursor: pointer;
  }
}
</style>